import React from "react";

const ArrowNextSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
    >
      <path
        d="M1.28125 1.28271L7.1701 7.17156C8.73219 8.73366 8.73219 11.2663 7.17009 12.8284L1.28125 18.7173"
        stroke="#4E4645"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default ArrowNextSmall;
