import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import "../styles/pages/about.scss";
import ArrowNextSmall from "../images/ArrowNextSmall";

const AboutPage = ({ data }) => {
  if (!data) return null;

  const { page } = data;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Seo page={page} />
      <div className="about">
        <Layout>
          <div className="max-width about-contents">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={page.data.body[0].items[0].title.text.replaceAll(
                " ",
                "-"
              )}
            >
              <div className="side-nav">
                <div className="about-top-info">
                  <h1>{page.data.page_title.text}</h1>
                  <GatsbyImage
                    style={{ borderRadius: 8 }}
                    image={page.data.about_image.gatsbyImageData}
                  />
                </div>
                <div className="content-nav">
                  <Nav variant="pills" className="flex-column">
                    {page.data.body[0].items.map(aboutItem => (
                      <Nav.Item>
                        <Nav.Link
                          eventKey={aboutItem.title.text.replaceAll(" ", "-")}
                          onClick={scrollToTop}
                        >
                          {aboutItem.title.text} <ArrowNextSmall />
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <div className="main-content-mobile">
                    <Tab.Content>
                      {page.data.body[0].items.map(aboutItem =>
                        aboutItem.type === "Normal" ? (
                          <Tab.Pane
                            eventKey={aboutItem.title.text.replaceAll(" ", "-")}
                            key={aboutItem.title.text.replaceAll(" ", "-")}
                          >
                            <PrismicRichText
                              field={aboutItem.content.richText}
                            />
                          </Tab.Pane>
                        ) : (
                          <Tab.Pane
                            eventKey={aboutItem.title.text.replaceAll(" ", "-")}
                            key={aboutItem.title.text.replaceAll(" ", "-")}
                            className="logos-container"
                          >
                            {page.data.body[1].items.map(logo => (
                              <figure
                                className="figure col-md"
                                key={logo.logo.alt}
                              >
                                <GatsbyImage
                                  image={logo.logo.gatsbyImageData}
                                  objectFit="contain"
                                  alt="logo"
                                />
                                <figcaption className="figure-caption">
                                  {logo.logo.alt}
                                </figcaption>
                              </figure>
                            ))}
                          </Tab.Pane>
                        )
                      )}
                    </Tab.Content>
                  </div>
                </div>
              </div>
              <div className="main-content-desktop">
                <Tab.Content>
                  {page.data.body[0].items.map((aboutItem, index) =>
                    aboutItem.type === "Normal" ? (
                      <Tab.Pane
                        eventKey={aboutItem.title.text.replaceAll(" ", "-")}
                        // key={aboutItem.title.text}
                      >
                        <PrismicRichText field={aboutItem.content.richText} />
                      </Tab.Pane>
                    ) : (
                      <Tab.Pane
                        eventKey={aboutItem.title.text}
                        key={aboutItem.title.text}
                        className="logos-container"
                      >
                        {page.data.body[1].items.map(logo => (
                          <figure className="figure col-md" key={logo.logo.alt}>
                            <GatsbyImage
                              image={logo.logo.gatsbyImageData}
                              objectFit="contain"
                              alt="logo"
                            />
                            <figcaption className="figure-caption">
                              {logo.logo.alt}
                            </figcaption>
                          </figure>
                        ))}
                      </Tab.Pane>
                    )
                  )}
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Layout>
      </div>
    </>
  );
};

export const query = graphql`
  query aboutPageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicAboutpage(lang: { eq: $lang }, id: { eq: $id }) {
      url
      type
      data {
        meta_title
        meta_description
        page_title {
          text
        }
        about_image {
          gatsbyImageData
          alt
        }
        body {
          ... on PrismicAboutpageDataBodyAboutContent {
            id
            items {
              content {
                richText
              }
              title {
                text
              }
              type
            }
          }
          ... on PrismicAboutpageDataBodyLogos {
            id
            items {
              logo {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
